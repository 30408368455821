@import "styles/variables";

.media-icon {
  width: 36px;
  height: 36px;
  border: 1px solid $default-color-black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $default-color-white;
  position: relative;

  &--with-text {
    width: max-content;
    border-radius: 16px;
    padding: 0 8px;
    margin-right: 48px !important;

    &::before {
      content: "";
      height: 100%;
      background: black;
      width: 1px;
      position: absolute;
      right: -26px;
    }

    span {
      margin-left: 8px;
      text-transform: uppercase;
    }
  }
}
