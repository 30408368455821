@import "./variables";

.title {
  color: $default-color-black;

  //ex: homepage titles (how it works, explore brands etc.)
  &--big {
    font: bold 24px/28px $default-font-montserrat;
    margin-bottom: 24px;

    @include laptop {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 36px;
    }
  }

  // ex: sneaker title - sneaker page (Nike Dunk Low)
  &--medium {
    font: bold 16px/18px $default-font-montserrat;

    @include laptop {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 24px;
    }
  }

  // ex:
  &--small {
  }
}
