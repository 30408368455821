@import "styles/variables";

.auth {
  font-size: 12px;
  text-transform: uppercase;
  position: relative;

  a {
    text-decoration: none;
    line-height: 12px;
  }

  &__actions {
    display: flex;
    cursor: pointer;

    @include to-tablet {
      display: none;
    }

    div:nth-child(1) {
      border-right: 1px solid $default-color-black;
      margin-right: 24px;
      padding-right: 24px;
    }
  }

  &__logged {
    display: flex;

    & > a {
      @include to-tablet {
        display: none !important;
      }
    }

    &-icon {
      margin: 0 24px 0 16px;
      width: 14px;
    }

    &-info {
      display: flex;
      align-items: center;
      position: relative;

      @include laptop {
        border-left: 1px solid $default-color-black;
      }

      &__menu {
        position: relative;

        @include to-tablet {
          display: none;
        }
      }

      &__user {
        padding: 0 0 40px 24px;
        margin-bottom: -40px;
      }

      &__notifications {
        cursor: pointer;
        position: relative;
        padding: 0 0 40px 24px;
        margin-bottom: -40px;

        &-icon {
          width: 17px;
        }

        &-container {
          background: $default-color-white;
          border-radius: 8px;
          position: absolute;
          padding: 36px 20px;
          right: 0;
          top: 30px;
          width: 100%;
          max-height: 440px;
          overflow: auto;
          display: none;
          width: 320px;
          box-shadow: 0px 3px 18px $default-color-boxshadow-grey;

          @include to-tablet {
            width: 100vw;
            right: -32px;
          }

          &--scrolled {
            top: 54px;
          }

          li:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }

      &__submenu {
        &--scrolled {
          top: 64px !important;
        }
      }
    }
  }
}

.auth__logged-info__menu:hover .auth__logged-info__submenu {
  display: flex;
}

.auth__logged-info__notifications:hover
  .auth__logged-info__notifications-container {
  display: block;
}
