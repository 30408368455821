@import "./variables";
@import "~bootstrap/scss/bootstrap";
@import "./responsive.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  color: $default-color-black;
  background: $default-color-light-grey;
  scroll-behavior: smooth;
}

.app {
  min-height: calc(100vh - 16px);
  padding-bottom: 64px;
}

.modal-header {
  border-bottom: none !important;
  padding: 0;

  button {
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 0 !important;
    z-index: 2;
  }

  @include to-tablet {
    .btn-close {
      left: 24px;
      right: unset !important;
    }
  }
}

.w-66 {
  width: 66%;
}

.w-33 {
  width: 33%;
}

.font-bold {
  font-weight: bold;
}

.modal-dialog {
  max-width: 600px;
}

.modal-content {
  border-radius: 20px;
}

.border-image {
  border-radius: 20px;
}

a {
  color: $default-color-black;

  &:hover {
    color: $default-color-black;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: $default-color-black !important;
}

.swiper-button-next {
  right: -2px !important;
}

.swiper-button-prev {
  left: -2px !important;
}

@include to-tablet {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.swiper-slide-thumb-active {
  img {
    padding-bottom: 2px !important;
    border-bottom: 2px solid $default-color-green !important;
  }
}

.swiper-pagination {
  position: relative !important;
  margin-top: 24px !important;

  &-bullet-active {
    background: $default-color-green !important;
    width: 40px !important;
    border-radius: 8px !important;
  }
}

.info-card {
  border-radius: 16px;
  background-color: $default-color-white;
  padding: 20px 24px;
  color: $default-color-black;
  box-shadow: 0 3px 15px $default-color-boxshadow-grey;
  margin-bottom: 24px;

  &--full-width {
    width: 100% !important;
  }

  &__title {
    font: 500 24px/36px $default-font-oswald;
    padding-bottom: 8px;
    border-bottom: 1px solid $default-color-grey;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    &-name,
    &-value {
      font: bold 16px/18px $default-font-oswald;
      margin-bottom: 16px;
    }

    &-name {
      font-weight: 500;
      min-width: 110px;
    }
  }
}

.account-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: flex-start;

  &__text {
    font: 900 24px/120% $default-font-montserrat;
    display: flex;
    justify-content: space-between;
  }

  &__edit {
    text-decoration: underline;
    font: bold 18px/120% $default-font-oswald;
    cursor: pointer;
    text-transform: uppercase;
  }
}

.wy {
  &-messages {
    max-height: calc(100vh - 80px - 15vh) !important;
    min-height: calc(100vh - 80px - 15vh) !important;
    overflow: scroll;
  }

  &-messenger-conversation {
    border-radius: 16px !important;
    background: $default-color-white;
    box-shadow: 0 3px 15px $default-color-boxshadow-grey;
  }

  &-appbar {
    display: none !important;
  }

  &-message {
    &-form {
      box-shadow: 0 3px 15px $default-color-boxshadow-grey;
      background-color: $default-color-grey !important;
    }

    &-editor-textfield {
      padding: 16px 24px !important;
      font: 300 16px/18px $default-font-montserrat !important;
      color: $default-color-black !important;
    }

    &-editor-inputs {
      align-items: center !important;
    }

    &-bubble {
      font: 300 16px/18px $default-font-montserrat !important;
      border-radius: 16px !important;
      background-color: $default-color-grey !important;

      .wy-reactions,
      .wy-button-icon {
        background-color: $default-color-grey !important;
      }
    }

    &-me {
      .wy-message-bubble,
      .wy-reactions,
      .wy-button-icon {
        background-color: $default-color-green !important;
      }
    }
  }

  &-icon {
    fill: $default-color-black !important;
  }
}

:root {
  --wy-primary: $default-color-green !important;
}

.container--having-banner {
  position: relative;
  top: -75px;
  height: 100vh;
  // margin-top: 10vh;

  @include mobile {
    overflow: hidden;
    // margin-top: 20vh;
  }
}

.PhoneInput {
  border: 1px solid $default-color-lighter-grey;
  padding: 12px 24px;
  border-radius: 20px;
  max-height: 52px;

  &--focus {
    border-color: $default-color-black;
  }
}

.PhoneInputInput {
  width: 138px;
  border: none;
  outline: none !important;
}

.error {
  &__text {
    font: normal 12px/14px $default-font-montserrat;
    margin-bottom: 16px;
    color: $default-color-red-error;
  }
}

.page-item.disabled {
  opacity: 75%;
  cursor: not-allowed;

  span {
    background: $default-color-grey !important;
  }
}

.page-link {
  &:focus {
    background-color: $default-color-green;
  }
}

.mobile-backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  opacity: 0.85;
  background: $default-color-black;
}

.offcanvas-start {
  background-color: transparent !important;
  // width: 85vw !important;
}

.page-item:last-child .page-link {
  margin-right: 0 !important;
}

.flip-countdown {
  @media (max-width: 1000px) { 
    zoom: 0.4;
  }

  @include laptop {
    zoom: 1;
  }
}