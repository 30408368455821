@import "styles/variables";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: $default-font-montserrat;
  color: $default-color-black;
  margin-bottom: 16px;
  width: 100%;

  @include laptop {
    margin-bottom: 24px;
    width: unset;
  }

  &:focus-within label {
    transform: translate(0, 4px) scale(0.6);
    line-height: 18px;
    font-weight: 500;
  }

  &--filled {
    transform: translate(0, 4px) scale(0.6) !important;
    line-height: 18px;
    font-weight: 500;
  }

  &__label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 13px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: $default-color-black;
    font-size: 16px;
    font: normal 16px/16px inherit;
    left: 24px;
    opacity: 0.8;

    @include laptop {
      font-size: 16px;
      border-radius: 20px;
    }

    &--error {
      color: $default-color-red-error;
    }
  }

  &__input,
  &__textarea {
    border: none;
    border-radius: 30px;
    padding: 14px 20px;
    font-size: 16px;
    line-height: inherit;
    outline: none;
    box-shadow: 0px 3px 20px $default-color-light-green;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background: $default-color-white;
    font-weight: 400;
    resize: none;

    @include laptop {
      line-height: 18px;
      padding: 17px 24px;
      border-radius: 20px;
    }
  }

  &--border {
    border: 1px solid $default-color-lighter-grey;
    padding: 16px 24px;

    &:focus {
      border-color: $default-color-black;
    }

    &-error {
      border: 1px solid $default-color-red-error !important;
    }
  }

  &__eye {
    position: absolute;
    cursor: pointer;
    right: 24px;
    transform: translate(0, 18px);
    height: 16px;
  }

  &__error {
    font: normal 12px/14px $default-font-montserrat;
    color: $default-color-red-error;
    position: absolute;
    bottom: -16px;
  }
}
