@import "styles/variables";

.auth {
  color: $default-color-black;

  &__body {
    padding-block: 40px !important;

    @include laptop {
      padding: 60px 100px 40px 100px !important;
    }
  }

  &__title {
    font: 900 32px/32px $default-font-montserrat !important;
    text-align: center;

    @include laptop {
      font: 900 36px/48px $default-font-montserrat !important;
    }
  }

  &__description {
    font: normal 16px/24px $default-font-montserrat;
    margin: 8px 0 16px;
  }

  &__form-name {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 48%;
    }
  }

  &__forgot {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 24px;

    label {
      font: normal 14px/16px $default-font-montserrat;
      margin-right: 16px;

      @include laptop {
        font: normal 16px/24px $default-font-montserrat;
      }
    }
  }

  &__social {
    &-title {
      font: normal 14px/16px $default-font-montserrat;
      margin: 24px 0;
      text-align: center;

      @include laptop {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        color: white;
        height: 30px;
      }

      button {
        width: 45%;

        @include laptop {
          width: 176px;
        }
      }

      &--facebook {
        background: $default-color-blue;
      }

      &--google {
        background: $default-color-red-secondary;
      }
    }
  }

  &__create {
    text-align: center;
    margin-top: 40px;

    &__title {
      font: normal 14px/16px $default-font-montserrat;
      margin-bottom: 8px;

      @include laptop {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }

    button {
      margin: auto;
    }
  }
}
