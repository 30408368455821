@import "styles/variables";

.background {
  background: url(../../../assets/background-city.svg), $default-color-green;
  height: 180px;
  background-repeat: repeat-x;
  background-position: bottom;
  position: relative;
  top: -86px;

  @include laptop {
    top: -100px;
    height: 200px;
  }
}

.header {
  font-family: $default-font-oswald;
  background: transparent;
  font: bold 12px/15px $default-font-oswald;
  padding: 24px 0 !important;
  transition: 0.5s;

  &__backdrop {
    height: 100vh;
  }

  &__icon-menu {
    left: 64px;

    @include laptop {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }

  &__container {
    height: 38px;
  }

  &--scrolled {
    background: $default-color-white;
    padding: 20px 0 !important;
    box-shadow: 0px 3px 18px $default-color-boxshadow-grey;
  }

  &__left {
    display: none;

    @include laptop {
      display: flex;
    }

    &-dropdown {
      border-radius: 23px;
      background: $default-color-black;
      color: $default-color-white;
      font-weight: bold;
      width: 155px;
      height: 38px;
      text-align: center;
      text-transform: uppercase;
    }

    &-media {
      display: flex;

      a {
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__right {
    @include to-tablet {
      position: absolute;
      right: 32px;
      z-index: 9999;
    }

    @include laptop {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 998;
    position: relative;
    width: max-content;
    margin: auto;

    svg {
      height: 60px;

      @include laptop {
        height: 70px;
      }
    }
  }
}
