@import "styles/variables";

.button {
  border: none;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 3px 15px #002a283d;
  letter-spacing: 3.24px;
  font: bold 16px/20px $default-font-oswald;
  text-transform: uppercase;
  transition: 0.5s;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include laptop {
    height: 52px;
    font: bold 18px/26px $default-font-oswald;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover {
    box-shadow: none;
  }

  &--primary {
    background: $default-color-green;
    color: $default-color-black;
  }

  &--warning {
    background: $default-color-red-error;
    color: $default-color-white;
  }

  &--secondary {
    color: $default-color-black;
    background: transparent;

    svg {
      fill: $default-color-green;
    }

    &:hover {
      background: $default-color-green;
      fill: $default-color-black;

      svg {
        fill: $default-color-black;
      }
    }
  }

  &--secondary-outlined {
    border: 2px solid $default-color-black;
    color: $default-color-black;
    background: transparent;
  }

  &--warning-outlined {
    border: 2px solid $default-color-red-error;
    color: $default-color-red-error;
    background: transparent;
  }

  &--text {
    background: transparent;
    box-shadow: none;
    color: $default-color-black;
    width: max-content;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    height: auto;

    @include laptop {
      font-size: 20px;
    }
  }
}
